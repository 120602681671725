<template>
  <div class="upload-image">
    <a-upload
      name="avatar"
      list-type="picture-card"
      class="avatar-uploader"
      :disabled="disabled"
      :show-upload-list="maxSum > 1 ? true : false"
      :before-upload="beforeUpload"
      :file-list="fileList"
      multiple
      :custom-request="customRequest"
      :remove="handleSingleDel"
      @preview="previewImg"
      @change="handleChange"
    >
      <div v-if="maxSum === 1 && fileList.length !== 0" class="upload-file-contain">
        <img v-if="!pdfIcon" class="upload-file" :class="[ !modelUpload ? 'view_upload' : '']" :src="fileList[0].url" @error="preViewError">
        <img v-else class="upload-file-pdf" :src="defaultIcon">
        <span class="upload-file-actions-p">
          <div class="upload-file-actions">
            <a-icon v-if="!pdfIcon" type="eye" @click.stop="previewImg(fileList[0])" />
            <a-icon type="download" @click.stop="handleDownload(fileList[0])" />
            <a-icon v-if="showDel" type="delete" @click.stop="handleSingleDel(fileList[0])" />
          </div>
        </span>
      </div>
      <div v-else-if="fileList.length !== maxSum" class="flex-center">
        <a-icon
          :type="loading ? 'loading' : 'upload'"
          :style="{ fontSize: '16px', marginRight: '5px' }"
        />
        <div class="ant-upload-text">
          {{ uplaodTip }}
        </div>
      </div>
    </a-upload>
    <!-- <a-modal :visible="previewVisible" :z-index="9999" :footer="null" @cancel="previewVisible = false">
    </a-modal> -->
    <Modal :visible="previewVisible" :closable="true" overflow-auto :footer="''" :title="`查看大图`" :size="'normal'" :mask-closable="false" @cancel="previewVisible=false">
      <img alt="example" style="width: 100%" :src="previewImage">
    </Modal>
  </div>
</template>

<script>
import ImageCompressor from 'image-compressor.js' // 引入
import { upload, getSignature } from '@/services/upload/index.js'
// import { IMG_PREVIEW } from '@/services/api.js'

/**
 * typeList<Array>
 */
export default {
  name: 'UploadImage',
  components: {
    Modal: () => import('@/components/CbModal/index.vue')
  },
  model: {
    prop: 'propFileList',
    event: 'change'
  },
  props: {
    // 图片类型列表
    typeList: {
      default: () => ['jpeg', 'png'],
      type: Array
    },
    // 图片大小 单位M
    fileSize: {
      default: 2,
      type: Number
    },
    // 图片上传的文案
    uplaodTip: {
      default: '上传附件',
      type: String
    },
    // 最大上传数量
    maxSum: {
      default: 2,
      type: Number
    },
    // 图片是否压缩
    compress: {
      default: true,
      type: Boolean
    },
    // 图片列表
    propFileList: {
      type: Array,
      default: () => []
    },
    // 下面为业务属性
    // 文件类型,头像为avatar,其他文件为other
    fileType: {
      default: 'other',
      type: String,
      require: true
    },
    // 0 不敏感 1敏感
    sensitiveFile: {
      default: '1',
      type: String,
      require: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showDel: {
      type: Boolean,
      default: true
    },
    modelUpload: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      basePath: process.env.VUE_APP_API_BASE_URL,
      signatureId: '',
      imageUrl: '',
      fileList: [],
      loading: false,
      index: -1,
      previewVisible: false, // 预览弹窗显示
      previewImage: '', // 预览图片路径
      imgshow: true,
      pdfIcon: false,
      defaultIcon: require('@/assets/img/pdf_icon.png')
    }
  },
  watch: {
    fileList(newVal) {
      this.$emit('change', newVal)
      if (newVal.length > 0) {
        this.pdfIcon = newVal[0].name.split('.')[1] === 'pdf'
      }
    },
    propFileList: {
      immediate: true,
      handler: function(newVal) {
        if (newVal instanceof Array) {
          const targetVal = newVal.map((item, index) => {
            return {
              ...item,
              uid: -(index + 1), // 注意，这个uid一定不能少，否则上传失败
              name: item.fileName || '临时图片',
              status: 'done',
              url: item.fileUrl,
              percent: 100
            }
          })
          if (newVal.length > 0) { // 上传图片的数量是否超出
            ++this.index
          }
          // 防止死循环
          if (newVal.length === 0 || newVal[newVal.length - 1].status) return
          this.$set(this, 'fileList', targetVal)
        }
      }
    }
  },
  mounted() {
  },
  methods: {
    async getSignatureData(data) {
      const res = await getSignature({
        id: data
      })
      this.signatureId = res.data.signature
    },
    handleChange({ file, fileList }) {
      // 删除图片
      if (file.status && file.status === 'removed') {
        this.pdfIcon = false
        this.fileList = fileList
        this.index = fileList.length - 1
        this.$emit('fileDel', { file, fileList })
      }
      this.$emit('change', { file, fileList })
    },
    // 上传前验证
    beforeUpload({ name, size }) {
      return new Promise((resolve, reject) => {
        // 判断类型
        const { typeList, fileSize } = this
        const singleType = name.toLowerCase().split('.')
        const typeInclude = typeList.includes(singleType[1])
        if (!typeInclude) {
          this.$message.error('上传图片格式错误, 请重新选择')
          return reject(false)
        }
        // 判断大小
        const isLt2M = size / 1024 / 1024 < fileSize
        if (!isLt2M) {
          this.$message.error(`图片最大上传${fileSize}M`)
          return reject(false)
        }
        resolve(true)
      })
    },
    // 自定义上传的方法
    async customRequest(file) {
      // 设置图片的 realIndex 值
      // 该值用来计算 图片上传的相关信息
      const realIndex = ++this.index
      // 判断是否超出数量
      if (realIndex > this.maxSum - 1) {
        this.$message.error(`最多上传${this.maxSum}张`)
        this.index--
        return
      }
      const { name, uid } = file.file
      const imgItem = {
        uid: uid, // 注意，这个uid一定不能少，否则上传失败
        name: name, // 比传
        status: 'uploading',
        url: '',
        percent: 0 // 注意不要写100。100表示上传完成
      }
      this.fileList.push(imgItem)
      const form = new FormData()
      const realindex = this.fileList.length - 1
      if (this.compress) {
        let result
        if (this.fileList[0].name.slice(-3) === 'pdf') {
          result = file.file
        } else {
          result = await this.compressHandle(file.file)
        }
        const newfiles = new window.File([result], result.name, { type: result.type })
        form.append('file', newfiles)
        form.append('fileName', newfiles.name)
      } else {
        form.append('file', file.file)
        form.append('fileName', file.file.name)
      }
      form.append('fileType', this.fileType)
      form.append('sensitiveFile', this.sensitiveFile)
      const res = await upload(form)
      if (res.code === 100) {
        let url = ''
        if (this.sensitiveFile === '1') {
          const signatureRes = await getSignature({
            id: res.data.fileId
          })
          if (signatureRes.code === 100) {
            url = `${this.basePath}/hrsaas-storage/image/thumbnail?id=${res.data.fileId}&signature=${signatureRes.data.signature}&expireTime=${signatureRes.data.time}`
          }
        } else {
          url = `${this.basePath}/hrsaas-storage/image/thumbnail?id=${res.data.fileId}`
        }
        const rimgItem = {
          ...imgItem
        }
        rimgItem.name = res.data.fileName
        rimgItem.originFileName = res.data.originFileName
        rimgItem.fileId = res.data.fileId
        rimgItem.staffFileId = res.data.staffFileId
        rimgItem.url = url
        rimgItem.status = 'done'
        // this.$set(this.fileList, (realindex), rimgItem)
        this.fileList.splice(realindex, 1, rimgItem)
        this.$emit('fileUpload', { file: form, fileList: this.fileList, fileId: res.data.fileId })
      }
    },
    // 压缩图片
    compressHandle(file) {
      return new Promise((resolve, reject) => {
        new ImageCompressor(file, {
          quality: 0.6,
          success(result) {
            return resolve(result)
          },
          error(e) {
            reject(e)
          }
        })
      })
    },
    // 单独预览
    previewImg(file) {
      this.previewImage = file.url
      this.previewVisible = true
    },
    // 单独删除
    handleSingleDel(file) {
      if (this.modelUpload) {
        this.$Reconfirm({
          title: '提示',
          content: `确认删除${file.originFileName || file.name}吗？`
        }).then(() => {
          this.pdfIcon = false
          this.index = -1
          this.fileList = this.fileList.filter(item => item.fileId !== file.fileId)
          this.$emit('fileDel', { file })
        }).catch(() => {
          console.log('cancel')
        })
      } else {
        this.pdfIcon = false
        this.index = -1
        this.fileList = this.fileList.filter(item => item.fileId !== file.fileId)
        this.$emit('fileDel', { file })
      }
    },
    // 单独下载
    handleDownload(file) {
      if (file.fileId) { // 解决企业信息--编辑时下载及上传文件在点击下载 获取signature和time
        getSignature({ id: file.fileId }).then(res => {
          file['signature'] = res.data.signature
          file['time'] = res.data.time
          window.location.href = `${this.basePath}/hrsaas-storage/file?id=${file.fileId}&signature=${file.signature}&expireTime=${file.time}`
        }).catch(err => { console.log('err', err) })
      }
    },
    preViewError() {
      const a = this.fileList[0].name.split('.')
      if (a.length === 1) {
        this.pdfIcon = true
      }
    }
  }
}
</script>

<style lang="less" scoped>
.upload-image {
  // /deep/.avatar-uploader {
  //   .ant-upload-select-picture-card {
  //     display: block;
  //     .ant-upload {
  //       display: block;
  //     }
  //   }
  // }
  .upload-file-contain {
    position: relative;
    .upload-file-actions-p {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(32, 42, 64, 0.5);
      opacity: 0;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      .upload-file-actions {
        text-align: right;
        .anticon {
          font-size: 16px;
          vertical-align: bottom;
          vertical-align: -webkit-baseline-middle;
          color: #fff;
          padding: 0 6px;
        }
      }
    }
    &:hover .upload-file-actions-p,
    &:hover .upload-file-actions {
      opacity: 1;
    }
  }
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    // margin-top: 8px;
    color: #666;
  }
}
.upload-file-pdf{
  width: 34px !important;
  height: 39px !important;
  margin: 40px;
}
.upload-file{
  // width: 100%;
  height: 100%;
}
.view_upload{
  width: 100% !important;
  height: 100% !important;
}
</style>
